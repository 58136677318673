import Handsontable from "handsontable"

$(document).on "turbo:load", ->
  sheet   = $("#incentives-sheet")
  data    = sheet.data("incentives")
  columns = [
    { type: "text",         data: "user_name",        readOnly: true,                save: false }
    { type: "text",         data: "user_email",       readOnly: true,                save: false }
    { type: "numeric",      data: "amount",                                          save: true  }
    { type: "numeric",      data: "potential_amount", readOnly: sheet.data("fixed"), save: true  }
    { type: "numeric",      data: "id",                                              save: true  }
  ]
  if sheet.length
    window.incentivesSheet = new Handsontable(sheet[0], {
      data:       data,
      rowHeaders: false,
      colHeaders: true,
      colHeaders: ["Name", "Email", "Earned Amount", "Potential Amount"]
      columns:    columns
      height:     "auto",
      licenseKey: "non-commercial-and-evaluation",
      stretchH:   "all",
      maxRows: data.length,
      hiddenColumns: {
        columns: [4]
      }
    });

$(document).on "click", "#incentives-sheet-save-button", (e) ->
  e.preventDefault()
  $(@).attr("disabled", true)
  schema  = window.incentivesSheet.getSettings().columns
  rows    = window.incentivesSheet.getData()
  incentives = []
  for row in rows
    incentive = {}
    for cell, i in row
      incentive[schema[i]["data"]] = cell if schema[i]["save"]
    incentives.push(incentive)

  input = $("<input type='hidden' name='incentive_data'>")
  input.val(JSON.stringify(incentives))
  $(@).append(input)
  $(@).parents("form")[0].requestSubmit()
