import Handsontable from "handsontable";

import "handsontable/dist/handsontable.full.css";

var emailValidator, presenceValidator;

emailValidator = function(query, callback) {
  return callback((query || '').toString().match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) != null);
};

presenceValidator = function(query, callback) {
  return callback((query || '').toString().length > 0);
};

Handsontable.validators.registerValidator("presenceValidator", presenceValidator);
Handsontable.validators.registerValidator("emailValidator", emailValidator);
