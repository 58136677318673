window.App ||= {}

window.intercomSettings = {
  custom_launcher_selector: "#intercom-launch"
};

import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
const context = require.context("./", true, /\.(js|ts|coffee)$/);

import "@hotwired/turbo-rails"
import { StreamActions } from "@hotwired/turbo"
require("./bootStimulus");

StreamActions.slide_out_close = function() {
  var element;
  element = document.getElementById("slide-out");
  element.slideOut.close();
}

StreamActions.modal_close = function() {
  var element, id;
  id      = this.getAttribute("modal_id")
  element = document.getElementById(id);
  element.modal.close();
}

StreamActions.table_reset = function() {
  var element, id;
  id      = this.getAttribute("table_name")
  element = document.getElementById(id)
  element.table.reset()
}

import * as ActiveStorage from "@rails/activestorage"

$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});

ActiveStorage.start()

function importAll(r) {
  r.keys().forEach(r)
}

importAll(require.context("./", true, /.js|.coffee$/))
importAll(require.context("stylesheets", true, /.scss$/))

require("trix")
require("@rails/actiontext")

import moment from "moment";
window.moment = moment

import { createPopper } from "@popperjs/core";
window.tooltip = createPopper

import { selectize } from "@selectize/selectize";
import "@selectize/selectize/dist/css/selectize.css";
