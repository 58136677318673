window.Browser || (window.Browser = {});

Browser.param = function(sParam) {
  var i, sPageURL, sParameterName, sURLVariables;
  sPageURL = window.location.search.substring(1);
  sURLVariables = sPageURL.split('&');
  sParameterName = void 0;
  i = void 0;
  i = 0;
  while (i < sURLVariables.length) {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      if (sParameterName[1] === void 0) {
        return true;
      } else {
        return decodeURIComponent(sParameterName[1]);
      }
    }
    i++;
  }
  return null;
};
