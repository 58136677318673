$(document).on 'click', '.input-toggle', (e) ->
  e.preventDefault()
  $(@).toggleClass('active')
  input = $(@).find('input[type="hidden"]')
  if $(@).hasClass('active')
    input.val($(input).data('checked'))
    input[0].dispatchEvent(new Event('change'))
  else
    input.val($(input).data('unchecked'))
    input[0].dispatchEvent(new Event('change'))
