String.prototype.capitalize = function() {
  return this.slice(0, 1).toUpperCase() + this.slice(1);
};

String.prototype.dasherize = function() {
  return this.replace(/[_\s]/g, '-').toLowerCase();
};

String.prototype.humanize = function() {
  return this.replace(/[_\s]/g, ' ').replace(/[-\s]/g, ' ');
};

String.prototype.pluralize = function() {
  return pluralize(this);
};

String.prototype.squish = function() {
  return this.replace(/\r?\n|\r/g, '').replace(/ +(?= )/g, '').trim();
};

String.prototype.titleize = function() {
  return this.replace(/[_\s]/g, ' ').humanize().replace(/(^[a-z]| [a-z]|-[a-z]|_[a-z])/g, function($1) {
    return $1.toUpperCase();
  });
};

String.prototype.underscore = function() {
  return this.replace(/([a-z])([A-Z])/g, '$1_$2').replace(/[-\s]/g, '_').toLowerCase();
};

String.prototype.camelCase = function() {
  return $.map(this.toLowerCase().trim().split(/[ -_]/g), function(word, i) {
    if (i === 0) {
      return word.toLowerCase();
    } else {
      return word.titleize();
    }
  }).join('');
};
