$(document).on 'focus', '.datepicker-input', ->
  unless $(@).data('datepickered')
    $(@).data('datepickered', true)
    $(@).datepicker
      language: 'en'
      dateFormat: 'M d, yyyy'
      onSelect: (formattedDate, date, instance) ->
        instance.hide()
        value = date.toISOString().split('T')[0]
        $(instance.el).parents('.input-container').find('.date-value').val(value)
        $(instance.el).trigger('change')

$(document).on 'change', '.datepicker-input', ->
  date = moment($(@).val(), 'MMM D, YYYY').format('YYYY-MM-DD')
  $(@).parents('.input-container').find('.date-value').val(date)
